<template lang="pug">
#BudGet
  .ui.grid
    .computer.only.row
      .column
        .ui.grid
          .eight.wide.column
            p.big.animated.bounceInDown Formatura

            .animated.flipInY
              p A vida é repleta de desafios, vitórias e <span class='big'>comemorações</span>! A graduação é certamente uma das <span class='big'>maiores</span> vitórias que você pode vir a ter na vida.

              p Nós da <span class='big'>Intensa</span> somos especializados em realizar as <span class='big'>formaturas</span> que serão para sempre boas lembranças em sua vida. Permita que nós sejamos parte disso e que usemos todo o nosso conhecimento e <span class='big'>experiência</span> na realização de seu sonho.

              p Seja você também um de nossos clientes e amigos <span class='big'>satisfeitos</span>. Nós estamos ansiosos para, <span class='big'>junto</span> com você, viver esse sonho e poder estar lá no momento de sua completa felicidade e satisfação.

              p Preenchendo o formulário ao lado, você nos envia uma mensagem falando um pouco sobre sua formatura. Estarem

          .eight.wide.column
            BudGetForm#Down
</template>

<script>
import BudGetForm from '../components/BudGetForm.vue'

export default {
  components: { BudGetForm }
}
</script>

<style lang="stylus" scoped>
a.ui.small.tag.fluid.label
  margin-top 5px !important

a.ui.cream
  background-color #6C624D !important
  color white !important

#Down
  margin-top 60px
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showDimmer: false
  },
  mutations: {
    SET_DIMMER_STATUS (state) {
      state.showDimmer = !state.showDimmer
    }
  },
  actions: {
    toggleDimmer (context) {
      context.commit('SET_DIMMER_STATUS')
    }
  },
  modules: {
  }
})

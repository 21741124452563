<template lang="pug">
#LocationMobile
  h1.ui.header.animated.slideInRight Localização
  .ui.divider
  .animated.zoomIn
    p Estamos localizado na Rua Capitão Lima, 280 - Santo Amaro, Recife - PE, 50040-080.
    p Ficamos próximos a Av. Cruz Cabugá, Padaria Galo, TV Jornal, TV Globo e escritório da Uber.
    p Facinho de chegar!
    .ui.divider

  .animated.fadeInLeft
    p.big Um pouco de história: Rua Capitão Lima

    p Antônio de Lima era capitão do pequeno contingente que guarnecia o Forte de São Jorge. Naquela antiga fortaleza, hoje não mais existente, o Capitão Lima resistiu bravamente ao ataque holandês. Morreu em combate no dia 20 de fevereiro de 1630. Esta rua foi aberta na área de um dos 12 sítios que existiam nessa região da cidade.

  .animated.fadeInDown
    .ui.two.item.small.inverted.orange.menu
      a.item(
        @click='whatLocation = "GoogleMaps"'
      ) Google Maps

      a.item(
        @click='whatLocation = "Waze"'
      ) Waze

    .ui.segment(v-if='whatLocation == "GoogleMaps"')
      template
        h3 Google map
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3950.470934751277!2d-34.88134758629992!3d-8.053349994201263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ab18941ef4bfb5%3A0x7361d61cbb8fbeb8!2sIntensa%20Eventos!5e0!3m2!1sen!2sbr!4v1585765124016!5m2!1sen!2sbr" width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

    .ui.segment(v-if='whatLocation == "Waze"')
      template
        h3 Waze
        <iframe src="https://embed.waze.com/iframe?zoom=17&lat=-8.053270&lon=-34.879210&ct=livemap&pin=1" width="100%" height="600" allowfullscreen></iframe>
</template>

<script>
export default {
  data () {
    return {
      whatLocation: 'GoogleMaps'
    }
  }
}
</script>

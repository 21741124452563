<template lang="pug">
#Home
  RouterView(name='UpperMenu')
  RouterView(name='Content')

  .ui.grid
    .computer.only.row
      .column
        BackgroundControl
        RouterView(name='DownMenu')

    .mobile.only.row
      .column

    .tablet.only.row
      .column
        RouterView(name='DownMenu')#DownMenu

</template>

<script>
import UpperMenu from '../components/UpperMenu.vue'
import BackgroundControl from '../components/BackgroundControl'

export default {
  components: { UpperMenu, BackgroundControl }
}
</script>

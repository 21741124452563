<template lang="pug">
  #imagePaginator
    .ui.segment
      transition(name='fade')
        img.ui.fluid.rounded.centered.image(
          v-if='show'
          :src='image'
        )
</template>

<script>

export default {
  data () {
    return {
      image: '',
      counter: null,
      show: false,
      latest: 0
    }
  },

  methods: {
    randomImage () {
      this.show = false
      let roundNumber = Math.round(Math.random() * 4)

      // ensuring tShat the new number is different of the last one
      while (roundNumber === this.latest) {
        roundNumber = Math.round(Math.random() * 4)
      }
      this.latest = roundNumber

      return `/paginator/in0${roundNumber}.jpg`
    }
  },

  mounted () {
    this.image = this.randomImage()
    this.show = true

    this.counter = setInterval(
      () => {
        this.image = this.randomImage()
        // I need the time to out animation
        setTimeout(() => { this.show = true }, 500)
      },
      5000
    )
  },

  beforeDestroy () { clearInterval(this.counter) }
}
</script>

<style lang="stylus" scoped>
.fade-enter-active,
.fade-leave-active
  transition-duration .5s

.fade-enter,
.fade-leave-to
  opacity 0
</style>

<template lang="pug">
.ui.grid
  .computer.only.row
    .column#app
      RouterView
      transition(
        enter-active-class='animated fadeIn'
        leave-active-class='animated fadeOut')
        #black(v-if='$store.state.showDimmer')

  .mobile.tablet.only.row
    .column
      RouterView
      transition(
        enter-active-class='animated fadeIn'
        leave-active-class='animated fadeOut')
        #black(v-if='$store.state.showDimmer')
</template>

<script>
import 'semantic-ui-css/semantic.min.css'
import 'animate.css/animate.min.css'

export default {
}
</script>

<style lang="stylus">
#app
  margin-left: 10px
  margin-right: 10px

body, h1
  font-family 'Ubuntu', sans-serif !important

.ui.orange.menu
  background-color #D0BD92 !important
  .item
    color black !important
    &.disabled
      color grey !important

.big
  font-size 1.6em

.ui.cream.button
.ui.tag.label
.item.header
  background-color #D1BE93 !important

i.cream.icon
.ui.header
  color #6C624D !important

p.small
  font-size 0.8em

body
  padding-bottom 140px
  background-color #FFF8E7

#black
  background url('/backgroundDark.svg')
  background-size cover
  position fixed
  width 100%
  height 100%
  z-index 3000
  top 0px

p.big.red
  color red
</style>

<template lang="pug">
#upperMenu
  .ui.grid
    .computer.tablet.only.row
      .column
        UpperMenuDesktop

    .mobile.only.row
      .column
        UpperMenuMobile
</template>

<script>
import UpperMenuDesktop from './desktop/UpperMenuDesktop.vue'
import UpperMenuMobile from './mobile/UpperMenuMobile.vue'

export default {
  components: { UpperMenuDesktop, UpperMenuMobile }
}
</script>

<template lang="pug">
#Contact
  .ui.grid
    .computer.only.row
      .column
        ContactDesktop

    .tablet.mobile.only.row
      .column
        ContactMobile
</template>

<script>
import ContactDesktop from '../components/desktop/ContactDesktop.vue'
import ContactMobile from '../components/mobile/ContactMobile.vue'

export default {
  components: { ContactDesktop, ContactMobile }
}
</script>

/* eslint-disable no-param-reassign */
import axios from 'axios'

axios.interceptors.request.use(
  (config) => {
    config.headers['Access-Control-Allow-Origin'] = '*'

    return config
  },
  (error) => Promise.reject(error)
)

export default axios

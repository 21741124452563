<template lang="pug">
#UpperImage
  img.ui.small.image#logo(src='/logoMobile.svg')

  .ui.two.item.small.menu#menu
    a.item(@click='toggleLeft') Opções
    a.item(@click='toggleRight') Siga-nos

  transition(
    enter-active-class='animated slideInLeft'
    leave-active-class='animated slideOutLeft'
  )
    OptionMenuMobile#leftMenu(@closeLeft='toggleLeft', v-if='left')

  transition(
    enter-active-class='animated slideInRight'
    leave-active-class='animated slideOutRight'
  )
    FollowUs#rightMenu(@closeRight='toggleRight', v-if='right')
</template>

<script>
import OptionMenuMobile from './OptionMenuMobile.vue'
import FollowUs from './FollowUs.vue'

export default {
  components: { OptionMenuMobile, FollowUs },
  data () {
    return { left: false, right: false }
  },

  methods: {
    toggleLeft () {
      this.$store.dispatch('toggleDimmer')
      this.left = !this.left
    },

    toggleRight () {
      this.$store.dispatch('toggleDimmer')
      this.right = !this.right
    }
  }
}
</script>

<style lang="stylus">
#UpperImage
  background-image url('/upperMobile.svg')
  background-repeat no-repeat
  background-position right
  background-size cover
  width 100%
  min-height 200px

#logo
  padding-top 30px
  padding-left 30px

#menu
  background #765316ff !important
  margin-top 60px !important
  .item
    color white !important

#leftMenu
  z-index 3010

#rightMenu
  z-index 3010
</style>

<template lang="pug">
  #Formatura
    .ui.form.mini.segment
      .two.fields
        .field
          input(
            placeholder='Seu nome'
            ref='name'
            v-model='nome'
          )
        .field
          input(
            placeholder='Número de pessoas'
            type='Number',
            v-model='formandos'
          )
      .two.fields
        .field
          input(
            placeholder='Seu email',
            v-model='email'
          )
        .field
          input(
            placeholder='Telefone para contato',
            v-model='telefone'
          )
      .three.fields
        .field
          label Você integra a comissão?
          .ui.mini.fluid.basic.buttons
            a.ui.button(@click='parte_comissao = (parte_comissao == "sim") ? " não" : "sim"')
              i.check.green.icon(v-if='parte_comissao == "sim"')
              i.close.icon(v-else)
              |{{ (parte_comissao == 'sim') ? 'Sim' : 'Não' }}
        .field
          label Ano de conclusão
          input(
            placeholder='Ano da conclusão'
            type='Number'
            :min='(new Date).getFullYear()'
            max= 2050,
            v-model='ano_conclusao'
          )
        .field
          label Semestre
          .ui.fluid.mini.buttons
            a(
              @click='semestre = "primeiro"'
              :class='[semestre == "primeiro" ? "ui green button" : "ui cream button"]'
            ) 1°
            a(
              @click='semestre = "segundo"'
              :class='[semestre == "segundo" ? "ui green button" : "ui cream button"]'
            ) 2°
      .two.fields
        .field
          input(
            placeholder='Nome da instituição'
            v-model='faculdade'
          )
        .field
          input(
            placeholder='Nome do curso',
            v-model='curso'
          )
      .field
        .ui.mini.segment
          p Clique nas opções abaixo para marcar ou desmarcar um item de interesse
          .ui.two.column.grid
            .column
              .ui.fluid.vertical.mini.buttons
                a.ui.button(@click='pushInterest("Combo de Fotos e Placa")' :class='[interesses.includes("Combo de Fotos e Placa") ? "cream" : ""]')
                  |Combo de Fotos e Placa

                a.ui.button(@click='pushInterest("Aula da Saudade")' :class='[interesses.includes("Aula da Saudade") ? "cream" : ""]')
                  |Aula da Saudade

                a.ui.button(@click='pushInterest("Festas 50%")' :class='[interesses.includes("Festas 50%") ? "cream" : ""]')
                  |Festas 50%

                a.ui.button(@click='pushInterest("Culto Ecumênico")' :class='[interesses.includes("Culto Ecumênico") ? "cream" : ""]')
                  |Culto Ecumênio

                a.ui.button(@click='pushInterest("Colação de Grau")' :class='[interesses.includes("Colação de Grau") ? "cream" : ""]')
                  |Colação de Grau

                a.ui.button(@click='pushInterest("Baile")' :class='[interesses.includes("Baile") ? "cream" : ""]')
                  |Baile

            .column
              textarea(placeholder='Observações', v-model='obs')
      .field
        a.ui.disabled.cream.mini.button(v-if='disabled') Enviando...

        a.ui.cream.mini.button(v-if='!disabled' @click='sendForm') Enviar
</template>

<script>
import axios from '../helpers/axios'

export default {
  data () {
    return {
      formToSend: new FormData(),
      nome: '',
      parte_comissao: 'sim',
      interesses: [],
      formandos: '',
      email: '',
      telefone: '',
      ano_conclusao: '',
      semestre: 'primeiro',
      faculdade: '',
      curso: '',
      obs: '',
      disabled: false
    }
  },

  methods: {
    pushInterest (value) {
      if (this.interesses.includes(value)) {
        this.interesses.splice(this.interesses.indexOf(value), 1)
      } else {
        this.interesses.push(value)
      }
    },

    sendForm () {
      this.disabled = true

      const url = 'http://intensaeventos.com.br/sendMailGraduation.php'

      this.formToSend.append('nome', this.nome)
      this.formToSend.append('parte_comissao', this.parte_comissao)
      this.formToSend.append('interesses', this.interesses)
      this.formToSend.append('formandos', this.formandos)
      this.formToSend.append('email', this.email)
      this.formToSend.append('telefone', this.telefone)
      this.formToSend.append('ano_conclusao', this.ano_conclusao)
      this.formToSend.append('semestre', this.semestre)
      this.formToSend.append('faculdade', this.faculdade)
      this.formToSend.append('curso', this.curso)
      this.formToSend.append('obs', this.obs)

      axios
        .post(url, this.formToSend)
        .then(() => {
          this.$router.push({ name: 'Conclusion' })
        })
    }
  },

  computed: {
    sortedInterests () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.interesses.sort()
    }
  },

  mounted () { this.$refs.name.focus() }
}

</script>

<style lang="stylus" scoped>
a.ui.small.tag.fluid.label
  margin-top 5px !important

a.ui.newGreen
  background-color green !important
  color white !important
</style>

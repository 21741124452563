<template lang="pug">
#IntensaMobile
  h1.ui.header.animated.slideInLeft Quem somos?

  .animated.flipInY
    p <span class='big'>A Intensa Eventos</span> é uma empresa que foi criada para realizar o evento dos seus sonhos!

    p Nós contamos com uma equipe com vasta <span class='big'>experiência</span> na área de <span class='big'>eventos</span> socias e somos <span class='big'>aficcionados</span> pela realização de nossos clientes e amigos.

    p Hoje o <span class='big'>Grupo</span> Intensa é formado pela <span class='big'>Intensa Eventos</span>, empresa voltada para o planejamento e execução de casamentos, aniversários e eventos corporativos.

    p Também integra o grupo a Intensa <span class='big'>Formaturas</span>, voltada para eventos de formaturas e que trabalha nesse segmento, desde o planejamento até a <span class='big'>execução</span> final, agregando os serviços de fotografia, <span class='big'>filmagem</span> e edição e, para isso, conta com um dos melhores estúdios fotográficos da região.

    ImagePaginator.animated.flipInX.delay-1s
</template>

<script>
import ImagePaginator from '../ImagePaginator.vue'

export default {
  components: { ImagePaginator }
}
</script>

<style lang="stylus">

</style>

import Vue from 'vue'
import VueRouter from 'vue-router'

// imports of views or components
import Home from '../views/Home.vue'
import Intensa from '../views/Intensa.vue'
import UpperMenu from '../components/UpperMenu.vue'
import DownMenu from '../components/desktop/DownMenu.vue'
import Location from '../views/Location.vue'
import Contact from '../views/Contact.vue'
import BudGet from '../views/BudGet.vue'
import Conclusion from '../views/Conclusion.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: Home,
    children: [
      { path: '', name: 'Intensa', components: { Content: Intensa, UpperMenu: UpperMenu, DownMenu: DownMenu } },

      { path: '/localizacao', name: 'Location', components: { Content: Location, UpperMenu: UpperMenu, DownMenu: DownMenu } },

      { path: '/contato', name: 'Contact', components: { Content: Contact, UpperMenu: UpperMenu, DownMenu: DownMenu } },

      { path: '/orcamento', name: 'BudGet', components: { Content: BudGet, UpperMenu: UpperMenu, DownMenu: DownMenu } },

      { path: '/conclusao', name: 'Conclusion', components: { Content: Conclusion, UpperMenu: UpperMenu, DownMenu: DownMenu } }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

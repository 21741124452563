<template lang="pug">
#DesktopUpperMenu
  .ui.borderless.inverted.orange.fixed.top.menu
    router-link.item(:to='{name: "Intensa"}')
      img(src='../../../public/logoTop.svg')
    .right.menu
      router-link.item(:to='{name: "Intensa"}') Intensa
      router-link.item(:to='{name: "Location"}') Localização
      router-link.item(:to='{name: "Contact"}') Orçamento
</template>

<script>
export default {}
</script>

<template lang="pug">
  #backgroundControl
    #imageBG.animated.slower.fadeIn(:style='image')
</template>

<script>
export default {
  data () {
    return { aleatoryNumber: 1, counter: null }
  },
  mounted () {
    this.counter = setInterval(
      () => {
        this.aleatoryNumber = Math.round(Math.random() * 9)
      },
      5000
    )
  },
  computed: {
    image () {
      return `background-image: url(/background/desktop-images/bg0${this.aleatoryNumber}.jpg);`
    }
  },
  beforeDestroy () { clearInterval(this.counter) }
}
</script>

<style lang="stylus">
#imageBG
  position fixed
  top 0
  width 100%
  height 100vh
  z-index -1
</style>

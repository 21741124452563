<template lang="pug">
  .ui.fixed.right.vertical.visible.small.icon.menu.sidebar#menu
   .heading.item Siga-nos

    a.item(
      href="https://www.facebook.com/intensaeventos/"
      target="blank"
    )
      i.huge.facebook.icon

    a.item(
      href="https://www.instagram.com/intensaeventos/"
      target="blank"
    )
      i.huge.instagram.icon

    a.item(
      href="https://www.youtube.com/channel/UCsQtIrXlLl-z7xQ5rg8bBfg"
      target="blank"
    )
      i.huge.youtube.icon

    a.item(
      href="https://www.tiktok.com/@intensaeventos?lang=pt-BR"
      target="blank"
    )
      img.ui.flutty.image(src="tiktok.svg")#tikTok

    a.item(
      href="https://api.whatsapp.com/send?1=pt_BR&phone=5581997934976"
      target="blank"
    )
      i.huge.whatsapp.icon

    A.item(
      href="https://intensaformaturas.blogspot.com/"
      target="blank"
    )
      i.huge.blogger.b.icon

    <a class="item" @click='$emit("closeRight")'>Fechar</a>
</template>

<script>
export default {}
</script>

<style lang="stylus" scoped>
#menu
  background #765316ff !important
  .item
    color white !important

#tikTok
  width 50px
  margin-left 35px
</style>

<template lang="pug">
  .ui.grid
    .computer.only.row
      .column
        .ui.eight.item.huge.borderless.bottom.fixed.inverted.orange.menu
          a(href='https://www.facebook.com/intensaeventos/' target="blank").item
            i.facebook.icon
            |Facebook
          a(href='https://www.instagram.com/intensaeventos/' target="blank").item
            i.instagram.icon
            |Instagram
          a(href='https://www.youtube.com/channel/UCsQtIrXlLl-z7xQ5rg8bBfg' target="blank").item
            i.youtube.icon
            |YouTube
          router-link.item(:to='{name: "Location"}')
            i.location.arrow.icon
            |Localização
          a(:href='client', target='blank').item
            i.user.icon
            |Cliente
          a.item(:href='whatsapp', target='blank')
            i.whatsapp.icon
            |Whatsapp
          a.item(:href='blog', target='blank')
            i.blogger.b.icon
            |Blog
          a(href="https://www.tiktok.com/@intensaeventos?lang=pt-BR" target="blank").item
            img.ui.mini.image(src="tiktok.svg")#tikTokDesktop
            |TikTok

    .tablet.mobile.only.row
      .column#mobileColumn
        .ui.seven.item.bottom.fixed.inverted.orange.menu
          a(href='https://www.facebook.com/intensaeventos/' target="blank").item
            i.facebook.icon

          a(href='https://www.instagram.com/intensaeventos/' target="blank").item
            i.instagram.icon

          a(href='https://www.youtube.com/channel/UCsQtIrXlLl-z7xQ5rg8bBfg' target="blank").item
            i.medium.youtube.icon

          a(:href='client', target='blank').item
            i.user.icon

          a.item(:href='whatsapp', target='blank')
            i.medium.whatsapp.icon

          a.item(:href='blog', target='blank')
            i.medium.blogger.b.icon

          a(href="https://www.tiktok.com/@intensaeventos?lang=pt-BR" target="blank").item
            img.ui.mini.image(src='tiktok.svg')#tiktokMobile

</template>

<script>
export default {
  data () {
    return {
      whatsapp: 'https://api.whatsapp.com/send?1=pt_BR&phone=5581997934976',
      client: 'http://cliente.intensaeventos.com.br/',
      blog: 'https://intensaformaturas.blogspot.com/',
      ShowMenu: false
    }
  }
}
</script>

<style lang="stylus">
#buttonBotton
  bottom 20px !important
  right 0px !important
  z-index 1000
  position fixed

#FristMenu
  margin-bottom 40px !important

#tikTokDesktop
  width 20px
  margin-right 10px

#mobileColumn
  width 120%
  margin-top 40px

#tiktokMobile
  width 20px
</style>

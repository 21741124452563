<template lang="pug">
#ContactMobile
  .ui.grid
    .mobile.only.row
      p.animated.fadeInLeft Preenchendo o formulário abaixo você nos envia uma mensagem. Estaremos retornando o mais rápido possível.

      BudGetForm#width

    .tablet.only.row#Down
      .ui.grid
        .row
          .ten.wide.column
            p.animated.fadeInLeft Preenchendo o formulário abaixo você nos envia uma mensagem. Estaremos retornando o mais rápido possível.

            BudGetForm#Up

          .six.wide.column
            .animated.flipInY
              p A vida é repleta de desafios, vitórias e <span class='big'>comemorações</span>! A graduação é certamente uma das <span class='big'>maiores</span> vitórias que você pode vir a ter na vida.

              p Nós da <span class='big'>Intensa</span> somos especializados em realizar as <span class='big'>formaturas</span> que serão para sempre boas lembranças em sua vida. Permita que nós sejamos parte disso e que usemos todo o nosso conhecimento e <span class='big'>experiência</span> na realização de seu sonho.

              p Seja você também um de nossos clientes e amigos <span class='big'>satisfeitos</span>. Nós estamos ansiosos para, <span class='big'>junto</span> com você, viver esse sonho e poder estar lá no momento de sua completa felicidade e satisfação.

              p Preenchendo o formulário ao lado, você nos envia uma mensagem falando um pouco sobre sua formatura.</template>

<script>
import BudGetForm from '../BudGetForm.vue'

export default {
  components: { BudGetForm }
}
</script>

<style lang="stylus">
#ContactMobile
  padding-left 20px

#Down
  margin-top 60px

#Up
  margin-bottom  60px

#width
  width 100%
</style>

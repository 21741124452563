<template lang="pug">
#Intensa
  .ui.grid
    .computer.only.row
      .column
        IntensaDesktop

    .mobile.tablet.only.row
      .column
        IntensaMobile
</template>

<script>
import IntensaDesktop from '../components/desktop/IntensaDesktop.vue'
import IntensaMobile from '../components/mobile/IntesaMobile.vue'

export default {
  components: { IntensaDesktop, IntensaMobile }
}
</script>

<style lang="stylus">
.tablet.mobile.only.row
  background-color #fff8e7ff !important
  padding-left 10px
</style>

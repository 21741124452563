<template>
  <div class="ui fixed left vertical visible small menu sidebar" id="menu">
    <div class="heading item">OPÇÕES</div>
    <a @click="goToURL('Intensa')" class="item">Quem somos</a>
    <a @click="goToURL('Location')" class="item">Localização</a>
    <a @click="goToURL('Contact')" class="item">Orçamento</a>
    <a href="http://cliente.intensaeventos.com.br" target='blank' class="item">Cliente</a>
    <a class="item" @click='$emit("closeLeft")'>Fechar</a>
  </div>
</template>

<script>
export default {
  methods: {
    goToURL (url) {
      this.$emit('closeLeft')
      this.$router.push({ name: url })
    }
  }
}
</script>

<style lang="stylus" scoped>
#menu
  background #765316ff !important
  .item
    color white !important
</style>

<template lang="pug">
#ContactDesktop
  p.big.animated.fadeInLeft Estamos sempre prontos pra falar com você!

  .ui.two.column.grid
    .column.animated.flipInX

      .ui.grid#LeftSegment
        .five.wide.column
          LeftSegmentContact

        .eleven.wide.column
          p Uma de nossas maiores <span class='big'>satisfações</span> é a de poder atender bem os nossos clientes e amigos, realizando sonhos e proporcionando a <span class='big'>felicidade</span> de um evento que entre para a <span class='big'>história</span> da vida de todos os nossos amigos.

          p É por isso que nós da Intensa Eventos <span class='big'>existimos</span>. É por isso que nos tornamos os <span class='big'>melhores</span> naquilo que fazemos. E fazemos com muito <span class='big'>amor</span> e dedicação.

          p Seja você também um de nossos clientes amigos <span class='big'>satisfeitos</span>. Permita que realizemos o seu <span class='big'>sonho</span>! Temos a certeza de que serão momentos inesquecíveis e lembranças maravilhosas.

    .column
      .ui.segment.animated.fadeInLeft.delay-1s
        img.ui.fluid.image(src='talkToUs.jpg')
</template>

<script>
import LeftSegmentContact from './LeftSegmentContact.vue'

export default {
  components: { LeftSegmentContact }
}
</script>

<template lang="pug">
#Location
  .ui.grid
    .computer.only.row
      .column
        LocationDesktop

    .mobile.tablet.only.row
      .column
        LocationMobile

</template>

<script>
import LocationDesktop from '../components/desktop/LocationDesktop'
import LocationMobile from '../components/mobile/LocationMobile'

export default {
  components: { LocationDesktop, LocationMobile }
}
</script>
